<template>
    <v-btn outlined x-small color="grey" @click="reload">Versão: 1.1.6</v-btn>
</template>

<script>
export default {
    methods: {
        reload() {
            window.location.reload(true);
        },
    },
};
</script>